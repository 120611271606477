export const TOGGLES = {
    // FEATURE LEVEL TOOGLES WILL BE LONG LIVED, DO NOT DELETE
    // Audits
    AUDIT_FEATURE: 'audit_feature',
    AUDIT_CHECKLISTS: 'audit_checklists',
    AUDIT_EVENTS: 'audit_events',
    AUDIT_NON_AUTH: 'audit_non_auth',
    AUDIT_QUESTION_LIBRARY: 'audit_question_library',
    AUDIT_SELF_ASSIGNMENT: 'audits_self_assignments',
    // Chemicals
    CHEMICAL_FEATURE: 'chemical_feature',
    CHEMICAL_LABELS: 'chemical_labels',
    CHEMICAL_NON_AUTH: 'chemical_non_auth',
    CHEMICAL_SDS: 'chemical_sds',
    CHEMICAL_SDS_BINDERS: 'chemical_sds_binders',
    CHEMICAL_SDS_MONITORING: 'chemical_sds_monitoring',
    // Incidents
    INCIDENT_FEATURE: 'incident_feature',
    INCIDENT_EMPLOYMENT_DATA: 'incident_employment_data',
    INCIDENT_OSHA_REPORTS: 'incident_osha_reports',
    INCIDENT_NON_AUTH: 'incident_non_auth',
    INCIDENT_TREND_GRID: 'incident_trend_grid',
    // Training
    TRAINING_FEATURE: 'training_feature',
    TRAINING_ASSET_LIBRARY: 'training_asset_library',
    TRAINING_EVENTS: 'training_events',
    TRAINING_ONLINE: 'training_online',
    TRAINING_PROGRAMS: 'training_programs',
    // Pimcore
    PIMCORE_FEATURE: 'pimcore_feature',
    PIMCORE_ZONE_ONE: 'pimcore_zone_one',
    PIMCORE_ZONE_TWO: 'pimcore_zone_two',
    PIMCORE_ZONE_THREE: 'pimcore_zone_three',
    PIMCORE_ZONE_FOUR: 'pimcore_zone_four',
    // Misc. features
    AEM_IMPLEMENTATION: 'aem_implementation',
    DISCUSSIONS_FEATURE: 'discussions_feature',
    EXPERT_HELP_FEATURE: 'expert_help_feature',
    IMPORTEXPORT_FEATURE: 'importexport_feature',
    NEWS_FEATURE: 'news_feature',
    PLANS_POLICIES_FEATURE: 'plans_policies_feature',
    REGSENSE_FEATURE: 'regsense_feature',
    REPORTING_CENTER_FEATURE: 'reporting_center_feature',
    TASK_FEATURE: 'task_feature',
    TERMS_OF_USE_FEATURE: 'tou_feature',
    TOPIC_INDEX_FEATURE: 'topic_index_feature',
    COVID_RECORDKEEPING: 'covid_recordkeeping',
    SMS_CLIENT_SIDE_CACHE: 'sms_client_side_cache',
    FILE_SCANNING_97762: 'file_scanning_97762',
    SMS_UNDER_MAINTENANCE: 'sms_under_maintenance',
    PNP_MYDOCS_TAB: 'pnp_mydocs_tab',
    CHECKLIST_MYDOCS_TAB: 'checklist_mydocs_tab',

    // FUNCTION LEVEL TOOGLES WILL BE SHORT LIVED, THESE WILL BE RELEASED REGULARLY
    // AND WILL REQUIRE CLEAN-UP TO KEEP TECH DEBT TO A SANE AMOUNT
    SECURITY_AUTH_TEST: 'security_auth_test',
    INCIDENT_TRENDS_CHARTING: 'incident_trends_charting',
    TRAINING_HIDE_ATTENDEE_COUNT: 'training_hide_attendee_count',
    INCIDENT_DART_SAFETY_REPORT_96612: 'incident_dart_safety_report_96612',
    RECURRING_AUDIT_EVENTS: 'recurring_audit_events',
    FORGOTPASSWORD_B2C_CREATE_OKTA: 'forgotpassword_b2c_create_okta',
    AUDIT_REVIEW_RADIO_BUTTONS_115791: 'audit_review_radio_buttons_115791',
    NAICS_2017: 'naics_2017',
    NAICS_2017_WIZARD: 'naics_2017_wizard',
    INCIDENT_SUMMARY_GROUPS: 'incident_summary_groups',
    SDS_MANAGEMENT_IN_REVIEW: 'sds_management_in_review',
    EMPLOYMENT_DATA_125349: 'employment_data_125349',
	AUDIT_WORKAREA: 'audit_event_work_area_optional',
    WEBCASTS_LINK: "webcasts_link",
    OKTA_UNIFIED_LOGIN: 'okta_unified_login',
    EDITORIAL_ASA_FILE_DOWNLOAD:'editorial_asa_file_download',
    ZENDESK_SSO_CONVERSION: 'zendesk_sso_conversion',
    EMPLOYEE_MASS_UPDATE_GROUP : 'employee_mass_update_group',
    CHEMICAL_MASS_GROUP_UPDATE: 'chemical_mass_group_update',
    SDS_NEW_VERSION_EMAIL: 'sds_new_version_email',
    INCIDENT_INTAKE_ENHANCEMENTS: 'incident_intake_enhancements',
    ONLINE_TRAINING_AG_GRID: 'online_training_ag_grid',
    TASK_LOCATION_WORKAREA: 'task_location_workarea',
    INCIDENT_SUMMARY_GROUP_CASCADE: 'incident_summary_group_cascade',
    INCIDENT_DART_MONTHLY_YEARLY: 'incident_dart_monthly_yearly',
    TRAINING_MULTI_ENROLLMENT: 'training_multi_enrollment',
    TASK_GRID_CREATEDBY: 'task_grid_createdby',
    TASK_AUTO_COMPLETED_DATE: 'task_auto_completed_date',
    AUDIT_GRID_CREATEDBY: 'audit_grid_createdby',
    MEDICAL_CP_40: 'medical_cp_40',
    CHEMICAL_REMOVE_REVIEWON: 'chemical_remove_reviewedOn',
    SDS_SEARCH_AGV2: 'sds_search_agv2',
    INCIDENT_INTAKE_FILE_UPLOAD: 'incident_intake_file_upload',
    TASK_TYPE_FORM: 'task_type_form',
};
